import axios from 'axios';
import resource from '@/api/resource';

const AxiosCancelToken = axios.CancelToken;
const NAMESPACE = 'MASTER';

export default {
  /**
   * @description Get brands
   * @returns {Promise}
   */
  getBrands() {
    let cancel;
    const CANCEL_TOKEN = `${NAMESPACE}_GET_BRANDS`;
    const request = resource.get('/brands', {
      cancelToken: new AxiosCancelToken((cancelRequest) => {
        cancel = cancelRequest;
      }),
    });

    window[CANCEL_TOKEN] = cancel;

    return request;
  },

  /**
   * @description Get locations
   * @returns {Promise}
   */
  getLocations() {
    let cancel;
    const CANCEL_TOKEN = `${NAMESPACE}_GET_LOCATIONS`;
    const request = resource.get('/locations', {
      cancelToken: new AxiosCancelToken((cancelRequest) => {
        cancel = cancelRequest;
      }),
    });

    window[CANCEL_TOKEN] = cancel;

    return request;
  },

  /**
   * @description Get reference numbers
   * @returns {Promise}
   */
  getReferenceNumbers() {
    let cancel;
    const CANCEL_TOKEN = `${NAMESPACE}_GET_REFERENCE_NUMBERS`;
    const request = resource.get('/reference-numbers', {
      cancelToken: new AxiosCancelToken((cancelRequest) => {
        cancel = cancelRequest;
      }),
    });

    window[CANCEL_TOKEN] = cancel;

    return request;
  },

  /**
   * @description Get models
   * @returns {Promise}
   */
  getModels() {
    let cancel;
    const CANCEL_TOKEN = `${NAMESPACE}_GET_MODELS`;
    const request = resource.get('/models', {
      cancelToken: new AxiosCancelToken((cancelRequest) => {
        cancel = cancelRequest;
      }),
    });

    window[CANCEL_TOKEN] = cancel;

    return request;
  },
};
